import { createClient } from '@supabase/supabase-js'
import { generateRandomString } from "../libs/utils";
import { useContext, useEffect, useState } from "react";
import { Context } from "../Store";

const supabaseUrl = 'https://api-degen.safupump.com'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzI3MDI0NDAwLAogICJleHAiOiAxODg0NzkwODAwCn0.Y3iFH3hdNu1rP3n1CIEDBbgLlOVe9X2X4QPX70tkO5U'

const supabase = createClient(supabaseUrl, supabaseKey)
const schema = 'opencoin'

export function useRefCode() {
    const [state, dispatch] = useContext(Context);
    const [app, setApp] = useState(null);
    const [db, setDb] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [refCode, setRefCode] = useState(null);

    useEffect(() => {
        if (state.account) getDetail({ table: 'fyt_referals', conditions: [{ column: 'address', value: state.account, operator: 'eq' }] });
    }, [state]);

    const getDetail = async ({ table, conditions }) => {
        setIsLoading(true);
        let query = supabase.schema(schema).from(table).select()
        conditions.forEach(condition => {
            switch (condition.operator) {
                case 'eq':
                    query = query.eq(condition.column, condition.value);
                    break;
                case 'gt':
                    query = query.gt(condition.column, condition.value);
                    break;
                case 'lt':
                    query = query.lt(condition.column, condition.value);
                    break;
                case 'gte':
                    query = query.gte(condition.column, condition.value);
                    break;
                case 'lte':
                    query = query.lte(condition.column, condition.value);
                    break;
                case 'neq':
                    query = query.neq(condition.column, condition.value);
                    break;
                case 'like':
                    query = query.like(condition.column, `%${condition.value}%`);
                    break;
                case 'ilike':
                    query = query.ilike(condition.column, `%${condition.value}%`);
                    break;
                default:
                    console.log('Operator tidak dikenal:', condition.operator);
            }
        });
        const { data, error } = await query;
        let exists = []
        data?.forEach((item) => {
            exists.push(item);
        })
        if (exists.length > 0) {
            setRefCode(exists[0]?.ref_code)
            setIsLoading(false)
        } else {
            generateRefCode();
        }
    }

    const generateRefCode = async () => {
        let code = generateRandomString(6).toString().toUpperCase();
        const { data: returnData, error } = await supabase.schema(schema)
            .from('fyt_referals')
            .select()
            .eq('address', state.account)
        if (returnData.length == 0) {
            const { data: returnedData, error: errorData } = await supabase.schema(schema)
                .from('fyt_referals')  // Nama tabel
                .insert({
                    address: state.account,
                    ref_code: code
                })
        } else {
            code = returnData[0].ref_code
        }


        setRefCode(code);
        setIsLoading(false);
    }


    return { app, db, refCode, isLoading, getDetail }
}



export const useSupabase = () => {
    const [state, dispatch] = useContext(Context);
    const [app, setApp] = useState(null);
    const [db, setDb] = useState(null);
    const [dataLists, setDataLists] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const putData = ({
        table,
        data,
        key
    }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { data: existingData, error: checkError } = await supabase
                    .schema(schema)
                    .from(table)
                    .select()
                    .eq(key, data[key])
                    .single();
                if (checkError) {
                    // Jika data tidak ditemukan, lakukan insert
                    const { data: returnedData, error: insertError } = await supabase
                        .schema(schema)
                        .from(table)
                        .insert(data);

                    if (insertError) {
                        console.error("Error inserting data:", insertError);
                        reject(insertError)
                    } else {
                        console.log(`Data ${table} inserted successfully:`, returnedData);
                        resolve(returnedData)
                    }
                } else {
                    // Jika data sudah ada, lakukan update
                    const { data: updatedData, error: updateError } = await supabase
                        .schema(schema)
                        .from(table)
                        .update(data)
                        .eq(key, data[key]);

                    if (updateError) {
                        console.error("Error updating data:", updateError);
                        reject(updateError)
                    } else {
                        console.log(`Data ${table} updated successfully:`, updatedData);
                        resolve(updatedData)
                    }
                }
                console.log(existingData)
                reject(checkError)

            } catch (error) {
                console.log('error', error)
                reject(error)
            }
        })

    }

    const getData = (
        {
            table,
            perPage = 2,
            currentPage = 0,
            orderColumn = "created_at",
            orderDir = "desc",
            conditions = [],
        }
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                const end = currentPage * perPage
                const start = (currentPage * perPage) - perPage
                let query = supabase.schema(schema).from(table).select().range(start, end).order(orderColumn, { ascending: orderDir == 'desc' ? false : true })
                conditions.forEach(condition => {
                    switch (condition.operator) {
                        case 'eq':
                            query = query.eq(condition.column, condition.value);
                            break;
                        case 'gt':
                            query = query.gt(condition.column, condition.value);
                            break;
                        case 'lt':
                            query = query.lt(condition.column, condition.value);
                            break;
                        case 'gte':
                            query = query.gte(condition.column, condition.value);
                            break;
                        case 'lte':
                            query = query.lte(condition.column, condition.value);
                            break;
                        case 'neq':
                            query = query.neq(condition.column, condition.value);
                            break;
                        case 'like':
                            query = query.like(condition.column, `%${condition.value}%`);
                            break;
                        case 'ilike':
                            query = query.ilike(condition.column, `%${condition.value}%`);
                            break;
                        default:
                            console.log('Operator tidak dikenal:', condition.operator);
                    }
                });
                const { data, error } = await query;

                if (error) {
                    reject(error)
                } else {
                    resolve(data)
                }
            } catch (error) {
                reject(error)
            }
        })

    }

    const getRow = ({ table, conditions }) => {
        return new Promise(async (resolve, reject) => {
            setIsLoading(true);
            try {
                let query = supabase.schema(schema).from(table).select()
                conditions.forEach(condition => {
                    switch (condition.operator) {
                        case 'eq':
                            query = query.eq(condition.column, condition.value);
                            break;
                        case 'gt':
                            query = query.gt(condition.column, condition.value);
                            break;
                        case 'lt':
                            query = query.lt(condition.column, condition.value);
                            break;
                        case 'gte':
                            query = query.gte(condition.column, condition.value);
                            break;
                        case 'lte':
                            query = query.lte(condition.column, condition.value);
                            break;
                        case 'neq':
                            query = query.neq(condition.column, condition.value);
                            break;
                        case 'like':
                            query = query.like(condition.column, `%${condition.value}%`);
                            break;
                        case 'ilike':
                            query = query.ilike(condition.column, `%${condition.value}%`);
                            break;
                        default:
                            console.log('Operator tidak dikenal:', condition.operator);
                    }
                });
                const { data, error } = await query;
                setIsLoading(false);
                setDataLists(data);
                resolve(data);
            } catch (e) {
                setIsLoading(false);
                reject(e);
            } finally {
                setIsLoading(false);
            }
        })
    }






    const getTransaction = async (
        perPage = 2,
        currentPage = 0,
        orderColumn = "created_at",
        orderDir = "desc",
        lastData = null,
        wherePayload = []
    ) => {
        const start = currentPage * perPage
        const end = start + perPage - 1
        return new Promise(async (resolve, reject) => {
            try {
                const { data, error, count } = await supabase.from('transactions')
                    .select('*', { count: 'exact' })
                    .range(start, end)
                    .order('created_at', { ascending: false })

                if (error) {

                }
            } catch (error) {

            }
        })
    }
    const insertReferalChild = async (data) => {
        const { error } = await supabase.schema('opencoin').from('referal_childs').insert(data)
        if (error) {
            console.error('Save data error', error);
            return;
        }
    }

    const insertTransaction = async (data) => {
        const { error } = await supabase.schema(schema).from('transactions').insert(data)
        if (error) {
            console.error('Save data error', error);
            return;
        }
    }




    return { app, db, isLoading, dataLists, putData, getData, getRow }
}