import React, { Fragment, useContext, useEffect, useState } from "react";
import { Header, Footer, LoadingWrapper } from "../components/fytcoin-ui";
import {
  RiArrowUpLine,
  RiExchangeDollarLine,
  RiExternalLinkLine,
  RiFileCopy2Line,
  RiTimer2Line,
  RiWalletLine,
} from "react-icons/ri";
import { ClipboardCopy } from "../components/ClipboarCopy";
import {
  amountFormat,
  formatNumber,
  formatNumberWithComma,
  truncate,
} from "../libs/WebooLib";
import {
  useCheckIsNeedApprove,
  useDynamicCallFunction,
  useDynamicSendFunction,
  useGetERC20Detail,
} from "../hooks/web3hook";
import { Context } from "../Store";
import { getAppByChainId, getEnv } from "../libs/Env";
import { copyToClipboard, parseFromWei } from "../libs/utils";
import CurrencyInput from "react-currency-input-field";
import { BigNumber } from "bignumber.js";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
// import { useFirestore, useRefCode } from "../hooks/useFirestore";
import { useSupabase, useRefCode } from "../hooks/useSupabase";
import { useParams } from "react-router-dom";
import axios from "axios";
const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";
export default function CommitPage() {
  const [
    { tokenSymbol, tokenBalance, tokenDecimal, isLoadingTokenInfo },
    fetchTokenInfo,
  ] = useGetERC20Detail();

  const [
    {
      tokenSymbol: tokenSymbolUSDT,
      tokenBalance: tokenBalanceUSDT,
      tokenDecimal: tokenDecimalUSDT,
      isLoadingTokenInfo: isLoadingTokenInfoUSDT,
    },
    fetchTokenInfoUSDT,
  ] = useGetERC20Detail();

  const [
    {
      tokenSymbol: tokenSymbolCommit,
      tokenBalance: tokenBalanceCommit,
      isLoadingTokenInfo: isLoadingTokenInfoCommit,
    },
    fetchTokenInfoCommit,
  ] = useGetERC20Detail();

  const [state, dispatch] = useContext(Context);
  const { app, db, refCode, isLoading } = useRefCode();
  const [totalReferrer, setTotalReferrer] = useState(0);
  const [percentDenominator, setPercentDenominator] = useState(0);
  const [percentForReferral, setPercentForReferral] = useState(0);
  const [minimumStakeInDollars, setMinimumStakeInDollars] = useState(0);
  const [minimumStakeInToken, setMinimumStakeInToken] = useState(0);
  const [amountStake, setAmountStake] = useState(0);
  const [totalPeriod, setTotalPeriod] = useState(0);
  const [isValidAmountStake, setIsValidAmountStake] = useState(false);
  const [availableReward, setAvailableReward] = useState(0);
  const [amountStakeInDollar, setAmountStakeInDollar] = useState(0);
  const [availableRewardFromReferral, setAvailableRewardFromReferral] =
    useState(0);
  const [claimedReward, setClaimedReward] = useState(0);
  const [claimedRewardFromReferral, setClaimedRewardFromReferral] = useState(0);
  const [{ isLoading: isLoadingDynamicCall }, dynamicCall] =
    useDynamicCallFunction();
  const [{ result: resultSend, isLoading: isLoadingSend }, dynamicSend] =
    useDynamicSendFunction();
  const [
    { amountAllowance, isNeedApprove, isLoadingCheck, txHashApprove },
    fetchCheckNeedApprove,
    fecthApprove,
  ] = useCheckIsNeedApprove();
  const [parentReferralAddress, setParentReferralAddress] = useState("");
  const [usdtDecimal, setUsdtDecimal] = useState(6);

  // const { putData, getRow } = useFirestore();
  const { putData, getRow } = useSupabase();
  const searchParams = new URLSearchParams(document.location.search);
  const [priceUSD, setPriceUSD] = useState(0);
  const [volumeUSD24h, setVolumeUSD24h] = useState(0);

  const initialize = () => {
    getParentReferral();
    getTotalReferrer();
    getPercentReferral();
    fetchTokenInfo(getAppByChainId(state.chainId).TOKEN_ADDRESS).then();
    fetchTokenInfoUSDT(getAppByChainId(state.chainId).USDT_ADDRESS).then();
    fetchTokenInfoCommit(getAppByChainId(state.chainId).COMMIT_ADDRESS).then();
    fetchCheckNeedApprove(
      getAppByChainId(state.chainId).USDT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ADDRESS
    ).then();
    getMinimumStakeInDollars();
    getMinimumStakeInToken();
    getAvailableReward();
    getClaimedReward();
    getTotalPeriod();
  };

  const getParentReferral = () => {
    console.log(getAppByChainId(state.chainId));
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "getDirectSponsorOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setParentReferralAddress(res);
      if (!res || res === ADDRESS_ZERO) {
        const _refCode = searchParams.get("ref");
        // getRow("Referals", [["refCode", "==", _refCode]]).then((res) => {
        //   setParentReferralAddress(res[0]?.address);
        // });
        getRow({
          table: "fyt_referals",
          conditions: [
            {
              column: "ref_code",
              value: _refCode,
              operator: "eq",
            },
          ],
        })
          .then((res) => {
            setParentReferralAddress(res[0]?.address);
          })
          .catch((err) => console.error(err));
      }
    });
  };
  const getTotalReferrer = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "totalReferrer",
      [state.web3.utils.toChecksumAddress(state.account)]
    )
      .then((res) => {
        console.log("total ref", res);
        setTotalReferrer(res);
      })
      .catch((err) => console.log("total ref", err));
  };

  const getMinimumStakeInDollars = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "minimumCommitInDollars",
      []
    ).then((res) => {
      setMinimumStakeInDollars(res);
    });
  };

  const getMinimumStakeInToken = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "getMinimumStakeAmount",
      []
    ).then((res) => {
      const _res = new BigNumber(res);
      const _one = new BigNumber(1);
      const _newRes = _res.plus(_res.multipliedBy(0.01));
      setMinimumStakeInToken(res);
    });
  };

  const getTotalPeriod = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "getTotalPeriodOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setTotalPeriod(res);
    });
  };

  const getAvailableReward = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "dividendOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setAvailableReward(res);
    });
  };

  const getAvailableRewardFromReferral = () => {
    // dynamicCall(
    //   getAppByChainId(state.chainId).COMMIT_ADDRESS,
    //   getAppByChainId(state.chainId).STAKING_ABI,
    //   "dividendOfReferrer",
    //   [state.web3.utils.toChecksumAddress(state.account)]
    // ).then((res) => {
    //   setAvailableRewardFromReferral(res);
    // });
  };

  const getClaimedReward = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "getTotalClaimedOf",
      [state.web3.utils.toChecksumAddress(state.account)]
    ).then((res) => {
      setClaimedReward(res);
    });
  };

  const getPercentReferral = () => {
    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "percentForReferrer",
      []
    ).then((res) => {
      setPercentForReferral(res);
    });

    dynamicCall(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "percentTaxDenominator",
      []
    ).then((res) => {
      setPercentDenominator(res);
    });
  };

  const getClaimedRewardFromReferral = () => {
    // dynamicCall(
    //   getAppByChainId(state.chainId).COMMIT_ADDRESS,
    //   getAppByChainId(state.chainId).STAKING_ABI,
    //   "getTotalClaimedReferrerOf",
    //   [state.web3.utils.toChecksumAddress(state.account)]
    // ).then((res) => {
    //   setClaimedRewardFromReferral(res);
    // });
  };
  const handleOnStakeAll = () => {
    const balance = new BigNumber(tokenBalanceUSDT);
    const _newBalance = balance.minus(0.001);
    setAmountStake(_newBalance.toFixed(4).toString());
  };

  const checkIsValidAmountStake = () => {
    const balance = new BigNumber(parseFromWei(minimumStakeInDollars));
    const amount = new BigNumber(amountStake);
    setIsValidAmountStake(amount.isGreaterThanOrEqualTo(balance));
  };

  const handleOnStakeHalf = () => {
    const balance = new BigNumber(tokenBalanceUSDT);
    const divider = new BigNumber(`1${"0".repeat(tokenDecimalUSDT)}`);
    const amount = balance.dividedBy(divider);
    const half = amount.dividedBy(2);

    setAmountStake(half.toFixed(4).toString());
  };

  const handleOnMin = () => {
    const amount = new BigNumber(minimumStakeInDollars);
    const divider = new BigNumber(`1${"0".repeat(tokenDecimalUSDT)}`);
    const value = amount.dividedBy(divider);
    setAmountStake(value.toFixed(4).toString());
  };

  const handleOnSubmit = async () => {
    if (isNeedApprove) {
      fecthApprove(
        getAppByChainId(state.chainId).USDT_ADDRESS,
        getAppByChainId(state.chainId).COMMIT_ADDRESS
      ).then();
    } else {
      dynamicSend(
        getAppByChainId(state.chainId).COMMIT_ADDRESS,
        getAppByChainId(state.chainId).COMMIT_ABI,
        "commit",
        [
          state.web3.utils.toWei(amountStake),
          state.web3.utils.toChecksumAddress(
            parentReferralAddress || ADDRESS_ZERO
          ),
        ],
        state.web3.utils.toWei(amountStake),
        tokenSymbolUSDT
      )
        .then((res) => {
          initialize();
          toast.success("Stake Success");
          // store data for referrer
          const _amountStake = new BigNumber(
            state.web3.utils.toWei(amountStake)
          );
          const _reward = _amountStake
            .multipliedBy(percentForReferral)
            .dividedBy(percentDenominator);
          console.log("percentForReferral", percentForReferral);
          console.log("percentDenominator", percentDenominator);
          console.log(_reward.toFixed());
          // putData("ReferalChilds", res.txHash, {
          //   hash: res.txHash,
          //   directSponsor: parentReferralAddress || ADDRESS_ZERO,
          //   address: state.web3.utils.toChecksumAddress(state.account),
          //   amountStake: state.web3.utils.toWei(amountStake),
          //   amountReward: _reward.toFixed().toString(),
          //   amountSymbol: tokenSymbol,
          // });

          putData({
            table: "fyt_referal_childs",
            data: {
              hash: res.txHash,
              direct_sponsor: parentReferralAddress || ADDRESS_ZERO,
              address: state.web3.utils.toChecksumAddress(state.account),
              amount_stake: state.web3.utils.toWei(amountStake),
              amount_reward: _reward.toFixed().toString(),
              amount_symbol: tokenSymbol,
            },
            key: "hash",
          })
            .then((r) => console.log(r))
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log("submit", err);
          const _amountStake = new BigNumber(
            state.web3.utils.toWei(amountStake)
          );
          const _reward = _amountStake
            .multipliedBy(percentForReferral)
            .dividedBy(percentDenominator);

          console.log("percentForReferral", percentForReferral);
          console.log("percentDenominator", percentDenominator);
          console.log("_amountStakesss", _reward.toFixed());
        });
    }
  };

  const handleOnSubmitClaimReward = async () => {
    dynamicSend(
      getAppByChainId(state.chainId).COMMIT_ADDRESS,
      getAppByChainId(state.chainId).COMMIT_ABI,
      "claimDividend",
      [],
      availableReward.toString(),
      tokenSymbol
    ).then((res) => {
      initialize();
    });
  };

  const handleOnSubmitClaimRewardReferral = async () => {
    // dynamicSend(
    //   getAppByChainId(state.chainId).COMMIT_ADDRESS,
    //   getAppByChainId(state.chainId).STAKING_ABI,
    //   "claimDividendReferrer",
    //   [],
    //   availableRewardFromReferral.toString(),
    //   tokenSymbol
    // ).then((res) => {
    //   initialize();
    // });
  };

  useEffect(() => {
    if (state.web3 && state.account) {
      initialize();
    }
  }, [state]);

  useEffect(() => {
    if (amountStake === 0) return;
    checkIsValidAmountStake();
  }, [amountStake]);

  useEffect(() => {
    // getTotalStakeInDollars();
  }, [tokenBalanceCommit]);

  const getTotalStakeInDollars = () => {
    // if (tokenBalanceStake === 0 || !tokenBalanceStake) return;
    // const amountIn = state.web3.utils.toWei(tokenBalanceStake);
    //
    // const path = [
    //   getAppByChainId(state.chainId).TOKEN_ADDRESS,
    //   getAppByChainId(state.chainId).USDT_ADDRESS,
    // ];
    // dynamicCall(
    //   getAppByChainId(state.chainId).ROUTER_ADDRESS,
    //   getAppByChainId(state.chainId).PANCAKE_ROUTER_ABI,
    //   "getAmountsOut",
    //   [amountIn, path]
    // ).then((res) => {
    //   setAmountStakeInDollar(state.web3.utils.fromWei(res[1]));
    // });
    // return 123;
  };

  const fetchTokenData = async () => {
    try {
      const response = await axios.get(
        "https://api.geckoterminal.com/api/v2/networks/bsc/tokens/0x1F19c26a7F9b7A673348258d2fef0B7dF1Edef32/pools?page=1"
      );
      setPriceUSD(response.data?.data[0]?.attributes?.token_price_usd);
      setVolumeUSD24h(response.data.data[0]?.attributes?.volume_usd?.h24);
    } catch (error) {
      console.error("Error fetching token data:", error);
    }
  };

  return (
    <>
      <Header />

      <section className="relative px-4 py-4 overflow-hidden bg-white md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">
        <h1>COMMIT PAGE</h1>
        <div className="w-full p-6 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
          <div className="flex flex-col items-center justify-between w-full lg:flex-row md:flex-col xl:flex-row">
            <div className="flex flex-col items-center gap-8 xl:flex-row lg:flex-row md:flex-row">
              <div className="flex items-center gap-3">
                <img
                  src="/images/icon-reward/fyt.png"
                  width="60px"
                  height="60px"
                  alt="opencoin"
                />
                <div className="space-y-1">
                  <h1 className="text-lg font-semibold">
                    ForYouTreaSure <span>(FYT)</span>
                  </h1>
                  <div className="flex items-center gap-3">
                    ${Number(priceUSD).toFixed(2)}{" "}
                    <span className="flex items-center gap-1 text-green-500">
                      <RiArrowUpLine /> 5%
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-lg font-semibold">Market Cap</h1>
                {/* <div className="flex items-center gap-3">$1,000,000</div> */}
                <div className="flex items-center gap-3">
                  Data will be add after listing
                </div>
              </div>
              <div className="space-y-1">
                <h1 className="text-lg font-semibold">24h Volume</h1>
                <div className="flex items-center gap-1">
                  <img
                    src="/images/icon-reward/fyt.png"
                    width="25px"
                    height="25px"
                    alt="opencoin"
                  />{" "}
                  ${Number(volumeUSD24h).toFixed(2)}
                </div>
                {/* <div className="flex items-center gap-1"><img src="/images/icon-reward/fyt.png" width="25px" height="25px" alt="opencoin" /> $3,000,200 = $1,000,000</div> */}
              </div>
            </div>
            <div className="flex items-center gap-4 mt-6 xl:mt-0 lg:mt-0 md:mt-6">
              <a
                href={`https://bscscan.com/token/${
                  getAppByChainId(state.chainId).TOKEN_ADDRESS
                }}`}
                target="_blank"
                rel="noreferrer"
                className="px-6 py-4 font-semibold text-center text-white rounded-xl bg-grayc-900 dark:bg-grayc-950 hover:bg-grayc-900 dark:hover:bg-yellowc-500 dark:hover:text-gray-900"
              >
                Token Contract
              </a>
              <a
                href="/#"
                className="px-6 py-4 font-semibold text-center text-gray-900 bg-white rounded-xl hover:bg-gray-200 dark:hover:bg-grayc-950 dark:hover:text-white"
              >
                Buy FYT
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full gap-10 mt-12 md:flex-row lg:flex-row xl:flex-row">
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 xl:space-y-2.5 lg:space-y-2.5 md:space-y-3 space-y-4 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                <div>Your FYT Balance</div>
                <div className="flex items-center gap-1 text-end">
                  <img
                    src="/images/icon-reward/fyt.png"
                    height="25px"
                    width="25px"
                    alt="opencoin-small"
                  />
                  <LoadingWrapper
                    isLoading={isLoadingTokenInfo}
                    children={
                      <div>
                        {amountFormat(tokenBalance)} {tokenSymbol}
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                <div>Your Referral Count</div>
                <div className="flex items-center gap-1 text-end">
                  <LoadingWrapper
                    isLoading={isLoadingDynamicCall}
                    children={<div>{totalReferrer}</div>}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                <div>Your Period Count</div>
                <div className="flex items-center gap-1 text-end">
                  <LoadingWrapper
                    isLoading={isLoadingDynamicCall}
                    children={<div>{totalPeriod}</div>}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 space-y-2 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <div className="flex items-center justify-between">
                <div>FYT Address</div>
                <div className="flex items-center gap-1 text-end">
                  <div className="flex items-center gap-2 px-4 py-2 font-bold bg-white border border-[#e3e3e4] dark:border-grayc-700 dark:bg-grayc-900 rounded-md">
                    <img
                      src="/images/icon-reward/bnb.svg"
                      width="15px"
                      height="15px"
                      alt="bsc"
                    />
                    <>
                      <a
                        href={`https://bscscan.com/token/${
                          getAppByChainId(state.chainId).TOKEN_ADDRESS
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="opencoin"
                        className="flex items-center gap-1 text-yellowc-500 dark:hover:text-yellowc-500 hover:text-yellowc-500"
                      >
                        {truncate(
                          getAppByChainId(state.chainId).TOKEN_ADDRESS,
                          4,
                          "...",
                          50
                        )}
                        <RiExternalLinkLine />
                      </a>
                      <ClipboardCopy
                        copyText={getAppByChainId(state.chainId).TOKEN_ADDRESS}
                      />
                    </>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>Commit Address</div>
                <div className="flex items-center gap-1 text-end">
                  <div className="flex items-center gap-2 px-4 py-2 font-bold bg-white border border-[#e3e3e4] dark:border-grayc-700 dark:bg-grayc-900 rounded-md">
                    <img
                      src="/images/icon-reward/bnb.svg"
                      width="15px"
                      height="15px"
                      alt="bsc"
                    />
                    <>
                      <a
                        href={`https://bscscan.com/token/${
                          getAppByChainId(state.chainId).COMMIT_ADDRESS
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="opencoin"
                        className="flex items-center gap-1 text-yellowc-500 dark:hover:text-yellowc-500 hover:text-yellowc-500"
                      >
                        {truncate(
                          getAppByChainId(state.chainId).COMMIT_ADDRESS,
                          4,
                          "...",
                          50
                        )}
                        <RiExternalLinkLine />
                      </a>
                      <ClipboardCopy
                        copyText={truncate(
                          getAppByChainId(state.chainId).COMMIT_ADDRESS
                        )}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full gap-10 mt-12 md:flex-row lg:flex-row xl:flex-row">
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 space-y-3 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <div className="flex items-center justify-between w-full">
                <a
                  href="/#"
                  className="flex items-center gap-1 text-black dark:text-white hover:text-yellowc-600 dark:hover:text-yellowc-500"
                >
                  <RiExchangeDollarLine />
                  <div className="font-semibold">Get FYT</div>
                </a>
                <a
                  href="/#"
                  className="flex items-center gap-1 text-black dark:text-white hover:text-yellowc-600 dark:hover:text-yellowc-500"
                >
                  <RiTimer2Line />
                  <div className="font-semibold">History</div>
                </a>
              </div>

              <div className="p-4 bg-white dark:bg-grayc-800 rounded-2xl">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-3">
                    <button
                      type={"button"}
                      onClick={() => handleOnStakeAll()}
                      className="px-6 py-1 text-sm text-gray-800 bg-gray-100 dark:bg-grayc-900 dark:text-grayc-100 border-grayc-400 dark:border-gray-900 hover:border-gray-700 dark:hover:border-grayc-500 rounded-xl"
                    >
                      Max
                    </button>
                    <button
                      type={"button"}
                      onClick={() => handleOnStakeHalf()}
                      className="px-6 py-1 text-sm text-gray-800 bg-gray-100 dark:bg-grayc-900 dark:text-grayc-100 border-grayc-400 dark:border-gray-900 hover:border-gray-700 dark:hover:border-grayc-500 rounded-xl"
                    >
                      Half
                    </button>
                    <button
                      type={"button"}
                      onClick={() => handleOnMin()}
                      className="px-6 py-1 text-sm text-gray-800 bg-gray-100 dark:bg-grayc-900 dark:text-grayc-100 border-grayc-400 dark:border-gray-900 hover:border-gray-700 dark:hover:border-grayc-500 rounded-xl"
                    >
                      Min
                    </button>
                  </div>

                  <div className="flex items-center gap-2">
                    <RiWalletLine />
                    <div>
                      {amountFormat(tokenBalanceUSDT, 2)} {tokenSymbolUSDT}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between w-full gap-4 my-4">
                  <CurrencyInput
                    className="w-8/12 gap-2 px-4 py-2 bg-gray-100 xl:w-10/12 lg:w-10/12 md:w-8/12 border-grayc-600 dark:border-grayc-900 dark:bg-grayc-900 rounded-xl"
                    placeholder="0"
                    value={amountStake}
                    onValueChange={(value) => {
                      setAmountStake(value);
                    }}
                  />
                  {/*<input type="number"*/}
                  {/*    min="0"*/}
                  {/*    onKeyDown={(e) =>*/}
                  {/*        ["ArrowUp", "ArrowDown", "e", "E", ".", "-", ",", "_", "+"].includes(e.key) && e.preventDefault()*/}
                  {/*    }*/}
                  {/*    onPaste={(e) => {*/}
                  {/*        e.preventDefault();*/}
                  {/*        return false;*/}
                  {/*    }}*/}
                  {/*       value={amountStake}*/}
                  {/*    placeholder="0"*/}
                  {/*    className="w-8/12 gap-2 px-4 py-2 bg-gray-100 xl:w-10/12 lg:w-10/12 md:w-8/12 border-grayc-600 dark:border-grayc-900 dark:bg-grayc-900 rounded-xl"*/}
                  {/*/>*/}
                  <div className="flex items-center w-4/12 gap-1 px-4 py-2 bg-gray-100 md:w-4/12 lg:w-2/12 xl:w-2/12 border-grayc-600 dark:border-gracy-900 dark:bg-grayc-900 rounded-xl">
                    <img
                      src="/images/icon-reward/usdt.png"
                      width="25px"
                      height="25px"
                      alt="opencoin-sm"
                    />
                    <div>{tokenSymbolUSDT}</div>
                  </div>
                </div>
                <div>
                  {isValidAmountStake === false && (
                    <small className={"text-red-800"}>Invalid Amount</small>
                  )}
                </div>
                <div>
                  Minimum Commit:{" "}
                  {parseFromWei(minimumStakeInDollars, tokenDecimalUSDT, 6)}{" "}
                  {tokenSymbolUSDT}
                  {/*={" "}*/}
                  {/*{amountFormat((minimumStakeInToken))}{" "}*/}
                  {/*{tokenSymbol}*/}
                </div>
              </div>

              <div className="flex items-center justify-between w-full gap-4 pt-3">
                <button
                  type={"button"}
                  className={
                    "w-full flex items-center justify-center gap-2 px-6 py-3 text-center text-white rounded-3xl " +
                    (isValidAmountStake
                      ? "bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_8%,_#9f7928_30%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] hover:bg-yellowc-500 hover:text-gray-900"
                      : "bg-grayc-700 text-white")
                  }
                  disabled={isValidAmountStake === false}
                  onClick={handleOnSubmit}
                >
                  {isNeedApprove ? "APPROVE" : "COMMIT"}
                  <div className={""}>
                    <ClipLoader
                      color={"#f59e0b"}
                      loading={isLoadingCheck || isLoadingSend}
                      size={20}
                    />
                  </div>
                </button>
                {/*<button className="w-full px-6 py-3 text-center text-white bg-grayc-900 hover:bg-grayc-950 dark:hover:bg-grayc-950 dark:bg-grayc-800 rounded-3xl">Unstake</button>*/}
              </div>
            </div>
          </div>
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className="w-full p-6 space-y-3 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <h1 className="mb-5 text-lg font-semibold">Commit Information</h1>
              <div className="space-y-3">
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Your Commited</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/fyt.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <LoadingWrapper
                      isLoading={isLoadingTokenInfoCommit}
                      children={
                        <div>
                          {amountFormat(tokenBalanceCommit)} {tokenSymbolCommit}{" "}
                          {/*(est {amountFormat(amountStakeInDollar, 2)} USDT)*/}
                          {/*({tokenBalanceStake / minimumStakeInDollars})*/}
                          {/*({getTotalStakeInDollars(tokenBalanceStake)})*/}
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Available Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/fyt.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <LoadingWrapper
                      isLoading={
                        isLoadingTokenInfoCommit || isLoadingDynamicCall
                      }
                      children={
                        <div>
                          {amountFormat(parseFromWei(availableReward))}{" "}
                          {tokenSymbol}
                          {/*($*/}
                          {/*{Number(*/}
                          {/*  amountFormat(parseFromWei(availableReward)) **/}
                          {/*    priceUSD*/}
                          {/*)}*/}
                          {/*)*/}
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Claimed Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/fyt.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <LoadingWrapper
                      isLoading={
                        isLoadingTokenInfoCommit || isLoadingDynamicCall
                      }
                      children={
                        <div>
                          {amountFormat(parseFromWei(claimedReward))}{" "}
                          {tokenSymbol}
                          {/*($*/}
                          {/*{Number(*/}
                          {/*  amountFormat(parseFromWei(claimedReward)) * priceUSD*/}
                          {/*)}*/}
                          {/*)*/}
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-4 pt-3">
                <button
                  type={"button"}
                  className="flex items-center gap-2 px-8 py-3 text-white bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_8%,_#9f7928_30%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] hover:bg-yellowc-500 rounded-xl"
                  onClick={handleOnSubmitClaimReward}
                >
                  <div>Claim Reward</div>
                  <div className={"flex items-center"}>
                    <ClipLoader
                      color={"#fff"}
                      loading={isLoadingCheck || isLoadingSend}
                      size={20}
                    />
                  </div>
                </button>
                {/*<button className="px-8 py-3 text-gray-900 bg-gray-400 hover:bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 rounded-xl">*/}
                {/*    No Reward*/}
                {/*</button>*/}
              </div>
            </div>
            <div className="w-full p-6 mt-12 space-y-3 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
              <h1 className="mb-5 text-lg font-semibold">
                Referral Information
              </h1>
              <div className="space-y-3" key={"refCode-" + refCode}>
                <div className="flex items-center justify-start w-full">
                  <div className="px-6 py-3 font-semibold text-black bg-yellowc-400 rounded-l-md whitespace-nowrap">
                    Referral Link
                  </div>
                  <input
                    type="text"
                    className="w-full px-6 py-3 text-black bg-white dark:bg-grayc-950 dark:text-white"
                    placeholder={
                      window.location.origin + "/commit?ref=" + refCode
                    }
                    defaultValue={
                      window.location.origin + "/commit?ref=" + refCode
                    }
                  />
                  <button
                    className="flex items-center justify-center px-4 py-4 text-black bg-yellowc-400 hover:bg-yellowc-600 rounded-r-md"
                    type={"button"}
                    onClick={() =>
                      copyToClipboard(
                        toast,
                        window.location.origin + "/commit?ref=" + refCode
                      )
                    }
                  >
                    <RiFileCopy2Line />
                  </button>
                  {/*{parentReferralAddress}*/}
                </div>
                <div></div>
                <div className="flex flex-col items-start justify-between pt-4 xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <div>
                      {(percentForReferral / percentDenominator) * 100} %
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Available Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/opc-coin.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <div>
                      {/* {amountFormatAdd(parseFromWei(availableRewardFromReferral), parseFromWei(availableRewardFromReferral2))}{" "} */}
                      {tokenSymbol}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-between xl:items-center lg:items-center md:items-start xl:flex-row lg:flex-row md:flex-col">
                  <div>Claimed Reward</div>
                  <div className="flex items-center gap-1 text-end">
                    <img
                      src="/images/icon-reward/opc-coin.png"
                      height="25px"
                      width="25px"
                      alt="opencoin-small"
                    />
                    <div>
                      {/* {amountFormat(parseFromWei(claimedRewardFromReferral), parseFromWei(claimedRewardFromReferral2))}{" "} */}
                      {tokenSymbol}
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 pt-3">
                  <button
                    type={"button"}
                    className="flex items-center gap-2 px-8 py-3 text-white bg-[radial-gradient(ellipse_farthest-corner_at_right_bottom,_#FEDB37_0%,_#FDB931_8%,_#9f7928_30%,_#8A6E2F_40%,_transparent_80%),_radial-gradient(ellipse_farthest-corner_at_left_top,_#FFFFFF_0%,_#FFFFAC_8%,_#D1B464_25%,_#5d4a1f_62.5%,_#5d4a1f_100%)] hover:bg-yellowc-600 rounded-xl"
                    onClick={handleOnSubmitClaimRewardReferral}
                  >
                    <div>Claim Reward</div>
                    <div className={""}>
                      <ClipLoader
                        color={"#fff"}
                        loading={isLoadingCheck || isLoadingSend}
                        size={20}
                      />
                    </div>
                  </button>
                  {/*<button className="px-8 py-3 text-gray-900 bg-gray-400 hover:bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 rounded-xl">*/}
                  {/*    No Reward*/}
                  {/*</button>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
