import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./Store";
import Homepage from "./pages/Homepage";
import CommitPage from "./pages/CommitPage";
import Referral from "./pages/Referral";
import Information from "./pages/Information";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import SpawnPage from "./pages/SpawnPage";

export default function Navigation() {
  return (
    <Store>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Homepage />} />
          <Route path={"/commit"} element={<CommitPage />} />
          <Route path={"/spawn"} element={<SpawnPage />} />
          <Route path={"/referral"} element={<Referral />} />
          <Route path={"/information"} element={<Information />} />
        </Routes>
      </BrowserRouter>
    </Store>
  );
}
